import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

export default function MyCard(props) {
  return (
        <Card >
            {!!props.title &&
                <CardHeader
                    title={props.title}
                    subheader={props.subTitle && props.subTitle}
                />
            }
            {!!props.media &&
                <CardMedia
                    component="img"
                    height="194"
                    image={props.media}
                />
            }
            <CardContent>
                {!!props.children &&
                    <Typography variant="body2">
                        {props.children}
                    </Typography>
                }
            </CardContent>
            {!!props.actions &&
                <CardActions>
                    {props.actions}
                </CardActions>
            }
        </Card>
    );
}
import React, { useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ru from 'date-fns/locale/ru';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import GroupSelect from "./GroupSelect";
import CategorySelect from "./CategorySelect";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import RandomIcon from '@mui/icons-material/Shuffle';
import MenuItem from '@mui/material/MenuItem';

export const lesson_types = {
    "opros": "Опрос",
    "frontopros": "Фронтальный опрос",
};

export default function LessonDialog(props) {
    const { onClose, open, data, onSave } = props;

    const [date, setDate] = useState(new Date());
    const [group, setGroup] = useState("");
    const [category, setCategory] = useState("");
    const [code, setCode] = useState("");
    const [type, setType] = useState("opros");
    const [questionTime, setQuestionTime] = useState(5);
    const [answerTime, setAnswerTime] = useState(60);
    const [allowerTime, setAllowerTime] = useState(60);

    useEffect(() => {
        if (!!data) {
            setDate(new Date(data.date));
            setCode(data.code);
            setType(data.type);
            setCategory(data.category);
            setGroup(data.group);
            setQuestionTime(data.questionTime);
            setAnswerTime(data.answerTime);
            setAllowerTime(data.allowerTime);
        }else{
            setDate(new Date());
            setCode("");
            setGroup("");
            setCategory("");
            setType("opros");
            setQuestionTime(5);
            setAnswerTime(60);
            setAllowerTime(60);
        }
    }, [data]);

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        onSave({
            date: date,
            group: group,
            category: category,
            code: code,
            type: type,
            questionTime: questionTime,
            answerTime: answerTime,
            allowerTime: allowerTime
        });
    }


    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{!!data ? "Редактирование" : "Добавление"} занятия</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
                    <DatePicker
                        label="Дата"
                        sx={{ mb: 1, mt: 1 }}
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                    />
                </LocalizationProvider>
                <GroupSelect
                    onChange={(e, val) => setGroup(val)} 
                    defaultValue={!!data ? data.group : undefined}
                 />
                <CategorySelect
                    onChange={(e,val) => setCategory(val)}
                    defaultValue={!!data ? data.category : undefined}
                />
                <TextField
                    margin="dense"
                    id="code"
                    label="Код активации"
                    fullWidth
                    variant="outlined"
                    value={code}
                    onChange={(e)=> setCode(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="генерация"
                                onClick={()=> setCode((Math.floor(Math.random() * 10000) + 10000).toString().substring(1))}
                            >
                                <RandomIcon />
                            </IconButton>
                        </InputAdornment>
                    }}
                />
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    id="typeselect"
                    select
                    fullWidth
                    label="Тип занятия"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                >
                    {Object.keys(lesson_types).map((option) => (
                        <MenuItem key={option} value={option}>
                            {lesson_types[option]}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    autoFocus
                    margin="dense"
                    type="number"
                    id="questionTime"
                    label="Время на подготовку (сек.)"
                    fullWidth
                    variant="outlined"
                    value={questionTime}
                    onChange={(e)=> setQuestionTime(e.target.value)}
                />
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    autoFocus
                    margin="dense"
                    type="number"
                    id="answerTime"
                    label="Время на ответ (сек.)"
                    fullWidth
                    variant="outlined"
                    value={answerTime}
                    onChange={(e)=> setAnswerTime(e.target.value)}
                />
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    autoFocus
                    margin="dense"
                    type="number"
                    id="allowerTime"
                    label="Время на занятие (мин.)"
                    fullWidth
                    variant="outlined"
                    value={allowerTime}
                    onChange={(e)=> setAllowerTime(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button onClick={handleSave}>Сохранить</Button>
            </DialogActions>
        </Dialog>
    );
}



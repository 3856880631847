import React, { useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function GroupDialog(props) {
    const { onClose, open, data, onSave } = props;

    const [name, setName] = useState("");

    useEffect(() => {
        if (!!data) {
            setName(data.name);
        }else{
            setName("");
        }
    }, [data]);

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        onSave({
            name: name,
        });
    }


    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{!!data ? "Редактирование" : "Добавление"} группы терминов</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Название"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e)=> setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button onClick={handleSave}>Сохранить</Button>
            </DialogActions>
        </Dialog>
    );
}


import React, { useState} from "react";
import axios from "axios";
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import {
    useNavigate
} from "react-router-dom";

export default function Home(props) {
    const [error, setError] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [code, setCode] = useState("");
    let navigate = useNavigate();

    const checkCode = async () => {
        try {
            setError(false);
            setServerError(false);
            let res = await axios.get(`/json/lesson/checkCode?code=${code}`)
            if (!!res.data.status && res.data.status === "ok") {
                navigate(`/lesson/${res.data.id}`);
            } else {
                setError(true);
                console.log('status error');
            }
        } catch (e) {
            setServerError(true);
            console.log(e)
        }
    }

    return (
        <Container maxWidth="sm" sx={{mt: 'auto', mb: 'auto'}}>
            {error &&
                <Alert severity="error" sx={{mb: 2}}>Не верный код запуска</Alert>
            }
            {serverError &&
                <Alert severity="error" sx={{mb: 2}}>Ошибка подключения к серверу</Alert>
            }
            <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                <TextField
                    label="Код запуска занятия"
                    variant="outlined"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                />
                <Button
                    variant="contained"
                    onClick={()=> checkCode()}
                >Начать</Button>
            </Stack>
        </Container>
    );
}



import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {React, useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import SiteRoutes from './SiteRoutes';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.ulspu.ru/sveden/pps/index.php?ELEMENT_ID=37161">
        Титов С.Н.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#355264',
    },
    secondary: {
      main: '#e4541d',
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
<Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <SiteRoutes />
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 1,mt: 'auto' }} component="footer">
        <Copyright />
      </Box>
      {/* End footer */}
      </Box>
    </ThemeProvider>
  );
}

export default App;
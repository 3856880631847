import Box from "@mui/material/Box";
import {useState,useEffect} from "react";
import Container from "@mui/material/Container";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import {TabPanel, a11yProps} from './components/TabPanel';
import GroupsGrid from "./components/GroupsGrid";
import CategoriesGrid from "./components/CategoriesGrid";
import LessonsGrid from "./components/LessonsGrid";
import { useParams} from "react-router-dom";


export default function Dashboard(props) {
    const theme = useTheme();
    let params = useParams();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    useEffect(() => {
        if(!!params.tab && Number.isInteger(parseInt(params.tab))){
            console.log(`tab=${params.tab}`);
            setValue(parseInt(params.tab));
        }
    }, [params.tab]);

    return (
        <>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Разделы панели управления"
                >
                    <Tab label="Занятия" {...a11yProps(0)} />
                    <Tab label="Термины" {...a11yProps(1)} />
                    <Tab label="Группы" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
        <Container  style={{ width: '100vw', maxWidth: '100vw' }}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <LessonsGrid />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <CategoriesGrid />
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <GroupsGrid />
                        </TabPanel>
                    </SwipeableViews>

                </Box>
            </Container>
        </>
    );
}
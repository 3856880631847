import React, { useState, useEffect} from "react";
import axios from "axios";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeakerIcon from '@mui/icons-material/Speaker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicIcon from '@mui/icons-material/Mic';
import PlayIcon from '@mui/icons-material/PlayArrow';
import MicOffIcon from '@mui/icons-material/MicOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { useParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { useTimer } from 'react-timer-hook';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;

/*
import { useTts } from 'tts-react'
import type { TTSHookProps } from 'tts-react'

interface CustomProps extends TTSHookProps {
  highlight?: boolean
}

const CustomTTSComponent = ({ children, highlight = false, voice, callback, autoPlay }: CustomProps) => {
  const { ttsChildren, state, play, stop, pause } = useTts({
    children,
    markTextAsSpoken: highlight,
    lang: voice.lang,
    markColor: "#e4541d",
    voice: voice,
    autoPlay: autoPlay,
    onEnd: callback,
  })

  return (
    <>
      {state.isPlaying ?
        <IconButton color="secondary" aria-label="остановить" onClick={stop}>
          <VolumeOffIcon />
        </IconButton>
        :
        <IconButton color="secondary" aria-label="прослушать" onClick={play}>
          <VolumeUpIcon />
        </IconButton>
      }
        {ttsChildren}
      </>
  )
}
*/

export default function Lesson() {
    let params = useParams();
    const synth = window.speechSynthesis;

    const [voices, setVoices] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedVoice, setSelectedVoice] = useState(0);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [lessonEnded, setLessonEnded] = useState(false);
    const [remindTime, setRemindTime] = useState(60);
    const [pupil, setPupil] = useState("");
    const [answerTime, setAnswerTime] = useState(20);
    const [questionTime, setQuestionTime] = useState(20);
    const [text, setText] = useState("");
    const [status, setStatus] = useState("loading");
    const [image, setImage] = useState();
    const [terminId, setTerminId] = useState();
    const [firstRun, setFirstRun] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [listening, setListening] = useState(false);
    const [userAnswer, setUserAnswer] = useState("");
    const [answer, setAnswer] = useState("");
    const [showYesNo, setShowYesNo] = useState(false);
    const [resultLoading, setResultLoading] = useState(false);
    const [correct, setCorrect] = useState(false);
    const [notCorrect, setNotCorrect] = useState(false);
    var hasSTTResult = false;
    var hadResults = false;
    var ttsAnswer = "";

    var yesNomode = false;

    const makeTts = (text, callback) => {
      const ssu = new SpeechSynthesisUtterance(text);
      ssu.voice = voices[selectedVoice];
      ssu.lang = voices[selectedVoice].lang;
      if(!!callback){
          ssu.onend = callback;
      }
      return ssu;
    }

    const stt = new SpeechRecognition();
    stt.continuous = false;
    stt.interimResults  = false;
    stt.lang = "ru-RU";

    stt.onaudiostart = () => {
        console.log(`audio start hasresutl ${hasSTTResult} => false`);
        hasSTTResult = false;
        setListening(true);
        hadResults = false;
    };
    stt.onspeechstart = () => {
        console.log(`speach start hasresutl ${hasSTTResult} => true`);
        hasSTTResult = true;
    }
    stt.onaudioend = () => {
        console.log(`audio end start ${hasSTTResult}`);
        setListening(false);
        if(!hasSTTResult){
            synth.speak(makeTts(`Не услышала ответа. Слушаю ещё раз`, () => {
                stt.start();
            }));
        }else{
            setTimeout(() => {
                if(!hadResults){
                    synth.speak(makeTts(`Не услышала ответа. Слушаю ещё раз`, () => {
                        stt.start();
                    }));
                }
            }, 1000);
        }
    };
    stt.onresult = (event) => {
        hadResults = true;
        console.log(`Reuslts showYesNo = ${yesNomode} hasSTT = ${hasSTTResult}`);
        console.dir(event.results);
        if (yesNomode) {
            setShowYesNo(false);
            //if (event.results[0].isFinal) {
                stt.stop();
                yesNomode = false;
                if (event.results[0][0].transcript === "да") {
                    //отпавить результат 
                    sendAnswer(ttsAnswer);
                } else {
                    //спросить снова
                    synth.speak(makeTts(`Слушаю термин ещё раз`, () => {
                        stt.start();
                    }));
                }
            //}
        }else{
            setUserAnswer(event.results[0][0].transcript);
            //if (event.results[0].isFinal) {
                stt.stop();
                ttsAnswer = event.results[0][0].transcript;
                if(ttsAnswer.trim().toLocaleLowerCase() == "отсутствует"){
                    sendAnswer("na");
                } else {
                    synth.speak(makeTts(`Ваш ответ ${event.results[0][0].transcript}. Да или нет?`, () => {
                        setShowYesNo(true);
                        yesNomode = true;
                        stt.start();
                    }));
                }
            //}
        }
    };

    /*
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    */


    const questionTimer = useTimer({
        expiryTimestamp: new Date('2222-01-01'), onExpire: () => {
            console.warn('question timer ended');
            setDialogOpen(true);
            setStatus('readingPupilName')
            synth.speak(makeTts(`Отвечает ${pupil}`, () => {
                setStatus('listenAnswer')
                stt.start();
            }));
        }
    });

    const answerTimer = useTimer({
        expiryTimestamp: new Date('2222-01-01'), onExpire: () => {
            sendAnswer(ttsAnswer);
        }
    });



    useEffect(() => {
        if (voices.length > 0 && firstRun) {
            console.log('voice effect');
            setFirstRun(false);
            nextTermin();
        }
    }, [voices]);

    useEffect(() => {
        console.log(`use efect ${status}`);
        if (status === "thinking") {
            const time = new Date();
            time.setSeconds(time.getSeconds() + questionTime);
            questionTimer.restart(time);
        } else if (status === "listenAnswer") {
            const time = new Date();
            time.setSeconds(time.getSeconds() + answerTime);
            answerTimer.restart(time);
        } else if (status === "sendingAnswer"){
            answerTimer.pause();
        }
    }, [status]);



    const populateVoiceList = () => {
        setVoices(synth.getVoices().filter((e) => e.lang === 'ru-RU'))
    };

    useEffect(() => {
        let id = setInterval(() => {
            if (synth.getVoices().length !== 0) {
                populateVoiceList();
                clearInterval(id);
            }
        }, 200);

    }, []);


    const handleVoiceClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleVoiceClose = (voiceIdx) => {
        if (!!voiceIdx && voiceIdx < voices.length) {
            setSelectedVoice(voiceIdx);
        }
        setAnchorEl(null);
    };


    const sendAnswer = async (answer) => {
        stt.stop();
        setShowYesNo(false);
        yesNomode = false;
        try {
            setStatus('sendingAnswer');
            setResultLoading(true);
            let res = await axios.post(`/json/lesson/sendAnswer?id=${params.id}`, {
                termin: terminId,
                answer: answer,
                pupil: pupil
            });
            setResultLoading(false);
            if (!!res.data.status && res.data.status === "ok") {
                if (answer === "na") {
                    synth.speak(makeTts("Отметка сделана", () => {
                        setDialogOpen(false);
                        nextTermin();
                    }));
                } else {
                    const correct = res.data.correct;
                    setAnswer(res.data.answer);
                    if (correct) {
                        setCorrect(true);
                    } else {
                        setNotCorrect(true);
                    }
                    synth.speak(makeTts(correct ? `Верно. Это ${res.data.answer}` : `Не верно. Правильный ответ ${res.data.answer}`, () => {
                        setCorrect(false);
                        setNotCorrect(false);
                        setDialogOpen(false);
                        nextTermin();
                    }));
                }
            } else {
                setServerError(true);
            }
        } catch (e) {
            setServerError(true);
            console.log(e)
        }
    }

    const nextTermin = async () => {
        try {
            setLoading(true);
            setServerError(false);
            setUserAnswer("");
            let res = await axios.get(`/json/lesson/nextTermin?id=${params.id}`)
            setLoading(false);
            if (!!res.data.status && res.data.status === "ok") {
                setRemindTime(res.data.remindTime);
                setPupil(res.data.pupil);
                setText(res.data.text);
                setImage(res.data.image);
                setAnswerTime(res.data.answerTime);
                setQuestionTime(res.data.questionTime);
                setTerminId(res.data.terminId);
                setStatus("readTermin");
                synth.speak(makeTts(res.data.text, () => setStatus("thinking")));

            } else if (!!res.data.status && res.data.status === "end") {
                setLessonEnded(true);
                synth.speak(makeTts("Опрос закончен. Всем спасибо!"));
            } else {
                setServerError(true);
            }
        } catch (e) {
            setServerError(true);
            console.log(e)
        }
    }

    return (
        <>
            <AppBar position="relative">
                <Toolbar>
                    <QuestionAnswerIcon sx={{ mr: 2 }} />
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        Терминатор ({remindTime} мин.)
                    </Typography>
                    <Button
                        startIcon={<SpeakerIcon />}
                        endIcon={<ArrowDropDownIcon />}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleVoiceClick}
                        color="inherit"
                    >
                        {voices.length > 0 ? voices[selectedVoice].name : "Нет голоса"}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleVoiceClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {voices.map((e, idx) => <MenuItem key={e.name} onClick={() => handleVoiceClose(idx)}>{e.name}</MenuItem>)}
                    </Menu>
                </Toolbar>
            </AppBar>
            <Container style={{
                width: '100vw',
                maxWidth: '100vw',
                display: 'flex',
                flexDirection: 'column',
                flex: 'auto',
            }}>
                <Box
                    style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                    }}
                >
                    {serverError ?
                        <Alert severity="error" sx={{ mb: 'auto', mt: 'auto' }}>Ошибка подключения к серверу</Alert> :
                        lessonEnded ?
                            <Alert severity="success" sx={{ mb: 'auto', mt: 'auto' }}>
                                Опрос закончен. Всем спасибо!
                            </Alert> :
                            loading ? <CircularProgress /> :
                                <>
                                    {!!image &&
                                        <Container maxWidth="sm" style={{ textAlign: 'center' }} sx={{ mb: 1 }}>
                                            <img
                                                src={`/json/images/get/${terminId}`}
                                                alt={"Избражение"}
                                                loading="lazy"
                                                align="center"
                                                style={{ maxHeight: '40vh', maxWidth: '80vw', marginLeft: 'auto', marginRight: 'auto' }}
                                            />
                                        </Container>
                                    }
                                    {voices.length > 0 &&
                                        <Typography variant="h5" align="center" color="text.secondary" paragraph >
                                            {text}
                                        </Typography>
                                    }
                                    {status === "thinking" && <>
                                        <Typography variant="h6" align="center" color="text.primary">
                                            Будет отвечать: <b>{pupil} </b>
                                            <Typography variant="body" align="center" color="secondary">
                                                ({questionTimer.seconds})
                                            </Typography>
                                        </Typography>
                                    </>
                                    }
                                </>
                    }
                </Box>
                <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
                    <DialogTitle>Отвечает {pupil} ({answerTimer.seconds})</DialogTitle>
                    <DialogContent>
                        <center>
                            {resultLoading  ?
                                <CircularProgress size={50}/>
                                :
                                correct ?
                                <>
                                        <CheckIcon color="success" sx={{ fontSize: 50 }} /> 
                                        <Typography
                                            variant="h4"
                                            align="center"
                                            color="text.primary"
                                            component="p"
                                            sx={{ mt: 1 }}
                                        >{answer}</Typography>
                                    </> :
                                    notCorrect ?
                                        <>
                                            <CloseIcon color="error" sx={{ fontSize: 50 }} />
                                            <Typography
                                                variant="h4"
                                                align="center"
                                                color="text.primary"
                                                component="p"
                                                sx={{ mt: 1 }}
                                            >{answer}</Typography>
                                        </> :
                                        <>
                                            {
                                                listening ?
                                                    <IconButton color="secondary" aria-label="Слуаю" >
                                                        <MicIcon sx={{ fontSize: 50 }} />
                                                    </IconButton>
                                                    :
                                                    <IconButton color="secondary" aria-label="Не слушаю" >
                                                        <MicOffIcon sx={{ fontSize: 50 }} />
                                                    </IconButton>
                                            }
                                            <Typography
                                                variant="h3"
                                                align="center"
                                                color="secondary"
                                            >
                                                {userAnswer}
                                            </Typography>
                                            {
                                                showYesNo &&
                                                <Typography
                                                    variant="h4"
                                                    align="center"
                                                    color="text.primary"
                                                    component="p"
                                                >
                                                    Да или Нет?
                                                </Typography>
                                            }
                                        </>
                            }
                        </center>
                    </DialogContent>
                </Dialog>
            </Container>
        </>
    );
}




import React, { useState, useEffect } from "react";
import axios from "axios";
import MyGrid from './MyGrid';
import CategoryDialog from './CategoryDialog';
import MyCard from './MyCard';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RightArrowIcon from '@mui/icons-material/ArrowForward';
import AlertDialog from "./AlertDialog";
import { Link} from "react-router-dom";

export default function CategoriesGrid(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState();
    const [groups, setGroups] = useState([]);
        
    const sendData = async (data) => {
        try {
            let res = await axios.post( `/json/dashboard/${!!currentCategory ? 'saveCategory?name='+currentCategory.name : 'addCategory'}`, data );
            if (!!res.data.status && res.data.status === "ok") {
                if(!!currentCategory){
                    let tmp = [...groups];
                    tmp[tmp.findIndex((e) => e.name === currentCategory.name)] = res.data.data;
                    setGroups(tmp);
                }else{
                    setGroups([res.data.data].concat(groups));
                }
                setCurrentCategory(null);
                setDialogOpen(false);
            } else {
                console.log("status error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loadGroups = async () => {
        try {
            let res = await axios.get(`/json/dashboard/getCategories?rnd=${Math.random()}`)
            if (!!res.data.status && res.data.status === "ok") {
                setGroups(res.data.data);
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteGroup = async (name) => {
        try {
            let res = await axios.get(`/json/dashboard/deleteCategory?name=${name}`)
            if (!!res.data.status && res.data.status === "ok") {
                let tmp = [...groups];
                tmp.splice(tmp.findIndex((e) => e.name === name),1);
                setGroups(tmp);
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <>
            <MyGrid
                items={groups.map((grp) => 
                    <MyCard 
                        title={grp.name} 
                        actions={<>
                            <IconButton color="error"
                                onClick={() => {
                                    setCurrentCategory(grp);
                                    setAlertOpen(true);

                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    setCurrentCategory(grp);
                                    setDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton as={Link} to={`/dashboard/termins/${grp.name}`}>
                                <RightArrowIcon />
                            </IconButton>
                        </>}
                    >{`Терминов: ${!!grp.count ? grp.count : 0}`}</MyCard>
                )}
                addHandler={() => { setDialogOpen(true) }}
            />
            <CategoryDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setCurrentCategory(null);
                }}
                data={currentCategory}
                onSave={(data) => {
                    sendData(data)
                }}
            />
            <AlertDialog
                title={`Удалить категорию терминов ${!!currentCategory && currentCategory.name}?`}
                text={"Вы действительно желаете удалить категорию? Это действие необратимо."}
                open={alertOpen}
                onClose={() => {
                    setAlertOpen(false);
                    setCurrentCategory(null);
                }}
                onAgree={() => {
                    deleteGroup(currentCategory.name);
                }}
            />
        </>
    );
}


import React, { useState, useEffect } from "react";
import axios from "axios";
import MyGrid from './MyGrid';
import LessonDialog from './LessonDialog';
import MyCard from './MyCard';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableIcon from '@mui/icons-material/TableChart';
import AlertDialog from "./AlertDialog";
import ResultsDialog from "./ResultsDialog";

export default function LessonsGrid(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState();
    const [groups, setGroups] = useState([]);
    const [results, setResults] = useState([]);
    const [resultsDialogOpen, setResultsDialogOpen] = useState(false);
        
    const sendData = async (data) => {
        try {
            let res = await axios.post( `/json/dashboard/${!!currentCategory ? 'saveLesson?id='+currentCategory._id : 'addLesson'}`, data );
            if (!!res.data.status && res.data.status === "ok") {
                if(!!currentCategory){
                    let tmp = [...groups];
                    tmp[tmp.findIndex((e) => e._id === currentCategory._id)] = res.data.data;
                    setGroups(tmp);
                }else{
                    setGroups([res.data.data].concat(groups));
                }
                setCurrentCategory(null);
                setDialogOpen(false);
            } else {
                console.log("status error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loadGroups = async () => {
        try {
            let res = await axios.get(`/json/dashboard/getLessons?rnd=${Math.random()}`)
            if (!!res.data.status && res.data.status === "ok") {
                setGroups(res.data.data);
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loadResults = async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/getResults?id=${id}`)
            if (!!res.data.status && res.data.status === "ok") {
                setResults(res.data.data);
                setResultsDialogOpen(true)
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteGroup = async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/deleteLesson?id=${id}`)
            if (!!res.data.status && res.data.status === "ok") {
                let tmp = [...groups];
                tmp.splice(tmp.findIndex((e) => e._id === id),1);
                setGroups(tmp);
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <>
            <MyGrid
                items={groups.map((grp) => 
                    <MyCard 
                        title={(new Date(grp.date)).toLocaleDateString()} 
                        subTitle={grp.group}
                        actions={<>
                            <IconButton color="error"
                                onClick={() => {
                                    setCurrentCategory(grp);
                                    setAlertOpen(true);

                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            <IconButton
                                style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    setCurrentCategory(grp);
                                    setDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                                loadResults(grp._id);
                            }} >
                                <TableIcon  />
                            </IconButton>
                        </>}
                    >Термины: {grp.category}<br/>Тип: {grp.type}<br/>Код: {grp.code}</MyCard>
                )}
                addHandler={() => { setDialogOpen(true) }}
            />
            <LessonDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setCurrentCategory(null);
                }}
                data={currentCategory}
                onSave={(data) => {
                    sendData(data)
                }}
            />
            <ResultsDialog
                open={resultsDialogOpen}
                onClose={() => {
                    setResultsDialogOpen(false);
                }}
                data={results}
            />
            <AlertDialog
                title={`Удалить занятие от ${!!currentCategory && (new Date(currentCategory.date)).toLocaleDateString() }?`}
                text={"Вы действительно желаете удалить занятие? Это действие необратимо."}
                open={alertOpen}
                onClose={() => {
                    setAlertOpen(false);
                    setCurrentCategory(null);
                }}
                onAgree={() => {
                    deleteGroup(currentCategory._id);
                }}
            />
        </>
    );
}



import {
    Route, Routes,
} from "react-router-dom";
import Dashboard from "./Dashboard";
import Termins from "./Termins";
import Home from './Home';
import Login from './Login';
import Lesson from './Lesson';

export default function SiteRoutes() {
    return (
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/dashboard/:tab"} element={<Dashboard />} />
          <Route path={"/dashboard/termins/:name"} element={<Termins />} />
          <Route path={"/lesson/:id"} element={<Lesson />} />
        </Routes>
    );
}


import MyCard from "./MyCard";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

export default function AddCard(props) {
  return (
    <MyCard
    >
      <center>
        <IconButton aria-label="добавить" onClick={props.onClick}>
          <AddIcon sx={{ height: 38, width: 38 }} />
        </IconButton>
      </center>
    </MyCard>
  );
}
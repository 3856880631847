import React, { useState, useEffect } from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DropzoneArea } from "mui-file-dropzone";
import { useDebounce } from 'use-debounce';
import Stack from '@mui/material/Stack';

export default function TerminDialog(props) {
    const { onClose, open, data, onSave, uploadImage, edit } = props;

    const [answer, setAnswer] = useState("");
    const [text, setText] = useState("");
    const [key, setKey] = useState(0);
    const [debounceKey] = useDebounce(key, 1000);

    useEffect(() => {
        console.log("use effect");
        console.dir(data);
        if (!!data) {
            setText(data.text);
            setAnswer(data.answer);
        }else{
            setText("");
            setAnswer("");
        }
    }, [data]);

    const handleClose = () => {
        onClose();
    };

    const handleSave = (callback) => {
        onSave({
            answer: answer,
            text: text,
            _id: !!data ? data._id : undefined,
        },callback);
        setText("");
        setAnswer("");
    }

    const handleUpload = (files) => {
        if(files.length === 1){
            if(!!data){
                uploadImage(files[0], null, () => setKey(Math.random()));
            }else{
                uploadImage(files[0], {
                    answer: answer,
                    text: text
                }, () => setKey(Math.random()));
            }
        }
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{!!data ? "Редактирование" : "Добавление"} термина</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Термин"
                    fullWidth
                    variant="standard"
                    value={answer}
                    onChange={(e)=> setAnswer(e.target.value)}
                />
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    id="text"
                    label="Определение"
                    multiline
                    minRows={5}
                    onChange={(e) => {
                        setText(e.target.value)
                    }}
                    fullWidth
                    value={text}
                />
                <Stack direction="row" spacing={1}>
                    {!!data && !!data.image &&
                        <img src={`/json/images/get/${data._id}`} style={{width: '70%'}} />
                    }
                    <DropzoneArea
                        key={debounceKey}
                        onChange={handleUpload}
                        acceptedFiles={['image/*']}
                        dropzoneText={"Перетащите изображение сюда"}
                        maxFileSize={10000000}
                        getFileAddedMessage={(fileName) => `Файл ${fileName} добавлен, начинаем загрузку`}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button onClick={handleSave}>{!!edit ? "Сохранить" : "Добавить"}</Button>
            </DialogActions>
        </Dialog>
    );
}


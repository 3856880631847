import React  from "react";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function ResultsDialog(props) {
    const { onClose, open, data } = props;

    const handleClose = () => {
        onClose();
    };


    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Результаты занятия</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="Таблица результатов">
                        <TableHead>
                            <TableRow>
                                <TableCell>Обучающийся</TableCell>
                                <TableCell align="right">Оценка</TableCell>
                                <TableCell align="right">Расшифровка</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!data && data.map((row) => (
                                <TableRow
                                    key={row.pupil}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.pupil}
                                    </TableCell>
                                    <TableCell align="right">{row.mark}</TableCell>
                                    <TableCell align="right">{row.description.join(',')}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    );
}




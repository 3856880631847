import React, { useEffect, useState} from 'react';
import axios from "axios";
import { useParams, Link} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BackIcon from '@mui/icons-material/ArrowBack';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MyGrid from "./components/MyGrid";
import MyCard from "./components/MyCard";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from "./components/AlertDialog";
import TerminDialog from "./components/TerminDialog";

export default function Termins(props) {
    let params = useParams();

    const [termins, setTermins] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [currentGroup, setCurrentGroup] = useState();

    useEffect(() => {
        loadTermins(params.name);
    }, [params.name]);

    const loadTermins = async (cat) => {
        try {
            let res = await axios.get(`/json/dashboard/getTermins?cat=${cat}&rnd=${Math.random()}`)
            if (!!res.data.status && res.data.status === "ok") {
                setTermins(res.data.data);
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }

    const sendData = async (data, callback) => {
        try {
            let res = await axios.post( `/json/dashboard/${!!currentGroup ? 'saveTermin?id='+currentGroup._id : 'addTermin'}`, Object.assign({category: params.name}, data ));
            if (!!res.data.status && res.data.status === "ok") {
                if(!!currentGroup){
                    let tmp = [...termins];
                    tmp[tmp.findIndex((e) => e._id === currentGroup._id)] = res.data.data;
                    setTermins(tmp);
                }else{
                    setTermins([res.data.data].concat(termins));
                }
                if(!!callback){
                    callback(res.data.data);
                }
            } else {
                console.log("status error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteGroup = async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/deleteTermin?id=${id}`);
            if (!!res.data.status && res.data.status === "ok") {
                let tmp = [...termins];
                tmp.splice(tmp.findIndex((e) => e._id === id),1);
                setTermins(tmp);
            } else {
                console.log('status error');
            }
        } catch (e) {
            console.log(e)
        }
    }

    const upload = async(data, file, callback) => {
        try {
            let formData = new FormData();
            formData.append("file", file);
            let res = await axios.post(`/json/images/upload?id=${data._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!res.data.status && res.data.status === "ok") {
                //console.log('current Group');
                //console.dir(data);
                const tmp = Object.assign({}, data);
                tmp.image = true;

                //console.log(`setting current group upload ${tmp}`);
                //console.dir(tmp);
                setCurrentGroup(tmp);

                const tmp2 = [...termins];
                tmp2[tmp2.findIndex((e) => e._id === data._id)].image = true;
                setTermins(tmp2);

                if(!!callback){
                    callback()
                }
                //props.showSnackbar("Файл загружен", "success");
            } else {
               // props.showSnackbar("Ошибка добавления файла", "error");
                console.log("error")
            }
        }catch (e) {
            //props.showSnackbar("Ошибка добавления файла", "error");
            console.log(e)
        }
    }


    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        as={Link}
                        to={"/dashboard/1"}
                    >
                        <BackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {params.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container style={{ width: '100vw', maxWidth: '100vw' }}>
                <Box
                    sx={{
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <MyGrid
                        items={termins.map((grp) =>
                            <MyCard
                                title={grp.answer}
                                actions={<>
                                    <IconButton color="error"
                                        onClick={() => {
                                            setCurrentGroup(grp);
                                            setAlertOpen(true);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => {
                                            setEdit(true);
                                            setCurrentGroup(grp);
                                            setDialogOpen(true);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </>}
                                media={!!grp.image && `/json/images/get/${grp._id}`}
                            >{grp.text}</MyCard>
                        )}
                        addHandler={() => { setDialogOpen(true) }}
                    />
                    <TerminDialog
                        open={dialogOpen}
                        onClose={() => {
                            setDialogOpen(false);
                            setCurrentGroup(null);
                            setEdit(false);
                        }}
                        data={currentGroup}
                        onSave={(data ) => {
                            sendData(data, () => {
                                if(edit){
                                    setDialogOpen(false);
                                    setEdit(false);
                                }
                                //console.log("set current group to null");
                                setCurrentGroup(null);
                            });
                        }}
                        uploadImage={(file, data, callback) => {
                            if(!!currentGroup){
                                upload(currentGroup,file, callback);
                            }else{
                                sendData(data, (data) => {
                                    upload(data,file, callback)
                                });
                            }
                        }}
                        edit={edit}
                    />
                    <AlertDialog
                        title={`Удалить термин ${!!currentGroup && currentGroup.answer}?`}
                        text={"Вы действительно желаете удалить термин? Это действие необратимо."}
                        open={alertOpen}
                        onClose={() => {
                            setAlertOpen(false);
                            setCurrentGroup(null);
                        }}
                        onAgree={() => {
                            deleteGroup(currentGroup._id);
                        }}
                    />
            </Box>
        </Container>
        </>
    );
}
import Grid from '@mui/material/Unstable_Grid2';
import AddCard from "./AddCard";

export default function MyGrid(props) {
  return (
        <Grid container spacing={2}>
            <Grid xs={6} md={4} lg={2}>
                <AddCard onClick={props.addHandler} />
            </Grid>
            {!!props.items && props.items.map((e,idx) => 
            <Grid key={`grid_${idx}`} xs={6} md={4} lg={2}>
                {e}
            </Grid>
            )}
        </Grid>
    );
}